import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ProjectManagementService } from 'src/app/shared/services';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-import-device-dialog',
  templateUrl: './import-device-dialog.component.html',
  styleUrls: ['./import-device-dialog.component.scss']
})
export class ImportDeviceDialogComponent implements OnInit {

  projectId: number = -1;
  editDataId: number = -1;
  cubicleId: number = -1;
  projectCreatedUserId: number = -1;

  constructor(
    private projectManagementService: ProjectManagementService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    // Assign passed data
    if (config.data) {
      this.projectId = config.data.projectId;
      this.editDataId = config.data.editDataId;
      this.cubicleId = config.data.cubicleId;
      this.projectCreatedUserId = config.data.projectCreatedUserId;
    }
  }

  ngOnInit(): void {}

  downloadTemplate() {
    const headers = [['Manufacturer', 'Model', 'Rating', 'Unit Power Loss (W)']];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(headers);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Template');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'Template.xlsx');
  }

  uploadCustomDeviceDetails(event: any): void {
    if (!event || !event.target || !event.target.files) {
      console.error("Invalid event or no file selected.");
      return;
    }
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e || !e.target) {
        console.error("Unable to read file.");
        return;
      }

      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const headers: string[] = excelData[0];
      const manufacturerIndex = headers.indexOf('Manufacturer');
      const modelIndex = headers.indexOf('Model');
      const ratingIndex = headers.indexOf('Rating');
      const unitPowerLossIndex = headers.indexOf('Unit Power Loss (W)');

      for (let i = 1; i < excelData.length; i++) {
        const rowData = excelData[i];
        const customSwitchgearDataRequest = {
          customSwitchGearId: this.editDataId,
          manufacturer: rowData[manufacturerIndex],
          model: rowData[modelIndex],
          rating: rowData[ratingIndex],
          unitPowerLoss: rowData[unitPowerLossIndex],
          projectId: this.projectId,
          cubicleId: this.cubicleId,
          projectCreatedUserId: this.projectCreatedUserId,
        };

        if (this.editDataId === -1) {
          this.projectManagementService
            .saveCustomDeviceDetailsToCollection(customSwitchgearDataRequest)
            .subscribe(
              (data) => {
                if (data && data.isSuccess) {
                  if (excelData.length - 1 === i) {
                    this.ref.close({ message: 'success', messageDetails: 'Custom device details saved successfully' });
                  }
                }
              },
              (error) => {
                console.error('Failed to save data to new collection', error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to save data to new collection' });
              }
            );
        }
      }
      event.target.value = '';
    };

    reader.readAsBinaryString(file);
  }

}
