import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { Chart, Tooltip } from 'chart.js'
import { DialogService } from 'primeng/dynamicdialog';
import { ProjectManagementService } from 'src/app/shared/services/project-management.service';
import { TempRiseCalculationRequest } from 'src/app/shared/models/project-management';
import { LinearGraphDataVM, CurvedGraphDataVM, TempRiseCalculationResponse } from 'src/app/shared/models/project-management/temp.rise.calculation.response';
import { ActivatedRoute } from '@angular/router';
import { QueryParmEnum } from 'src/app/shared/util/enumData';
@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss']
})

export class ViewReportComponent implements OnInit {
  a: number = 1;
  b: number = 0;
  c: number = 0;
  d: number = 0;
  e: number = 0;
  gr: number = 0;
  curvedRangeStart: number = 0;
  curvedRangeEnd: number = 40;
  rangeMax: number = 100;
  isGettingData: boolean = false;
  linearGraphData: LinearGraphDataVM | undefined;
  curvedGraphData: CurvedGraphDataVM | undefined;
  linearXValues: any;
  linearYValues: any;
  cubicleId: number = 1;
  reportData: any;
  queryParmEnum = QueryParmEnum;
  projectNo: any;
  changeTemp: string = 'no';
  maxTemperatureAllowed: number = 0; // This is bound to the input
  elevation: number = 0;
  numberOfFan: number = 0;
  fanCMFValue: number = 0;
  requiredCoolingPower: number = 0;
  fTable = [
    { height: 0, f: 0.8611 },
    { height: 500, f: 0.9178 },
    { height: 1000, f: 0.9722 },
    { height: 1500, f: 1.0552 },
    { height: 2000, f: 1.1389 },
    { height: 2500, f: 1.2224 },
    { height: 3000, f: 1.3245 }
  ];
  elevations = [
    { label: '0 m', value: 0 },
    { label: '500 m', value: 500 },
    { label: '1000 m', value: 1000 },
    { label: '1500 m', value: 1500 },
    { label: '2000 m', value: 2000 },
    { label: '2500 m', value: 2500 },
    { label: '3000 m', value: 3000 }
  ];
  selectedElevation = this.elevations[0].value;
  private currentChart: Chart | null = null;
  constructor(
    private projectManagementService: ProjectManagementService,
    public dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    Chart.register([Tooltip]);
    if (this.activatedRoute.snapshot.queryParams[QueryParmEnum.cubicleId]) {
      const cubicleId = atob(this.activatedRoute.snapshot.queryParams[this.queryParmEnum.cubicleId]);
      this.cubicleId = Number(cubicleId);
    }
    this.calculateTempRise();
  }

  calculateTempRise() {
    this.isGettingData = true;

    const tempRiseRequest: TempRiseCalculationRequest = {
      cubicleId: this.cubicleId
    }

    this.projectManagementService.calculateTempRise(tempRiseRequest).subscribe((data: TempRiseCalculationResponse) => {
      this.isGettingData = false;
      if (data) {
        //Report data
        this.reportData = data.reportData;
        this.projectNo = this.reportData.projectNo;

        //Linear graph data
        this.linearGraphData = data.linearGraph;
        this.linearXValues = this.linearGraphData?.points?.xValues;
        this.linearYValues = this.linearGraphData?.points?.yValues;

        if (this.linearYValues) {
          this.linearYValues = this.linearYValues.map((value: number) => value / 100);
        }

        this.d = this.linearGraphData?.coefficients?.d!;
        this.e = this.linearGraphData?.coefficients?.e!;

        //Curved graph data
        this.curvedGraphData = data.curvedGraph;
        this.b = this.curvedGraphData?.b!;
        this.c = this.curvedGraphData?.c!;
        this.gr = this.curvedGraphData?.gr!;

        this.generateChart();
      }
    })
  }

  generatePDF() {
    const doc = new jspdf({
      format: 'a4',
      orientation: 'portrait',
    });

    const reportBody = document.getElementById('report-body');
    const chart = document.getElementById('chart');

    // Capture report-body content
    html2canvas(reportBody!, { scale: 2, scrollY: -window.scrollY }).then((canvas1: any) => {
      const contentWidth1 = canvas1.width;
      const contentHeight1 = canvas1.height;

      doc.addImage(canvas1.toDataURL('image/png'), 'PNG', 10, 10, 190, 0);

      // Add a new page for the chart
      doc.addPage();

      // Capture chart content
      html2canvas(chart!, { scale: 2, scrollY: -window.scrollY }).then((canvas2: any) => {
        const contentWidth2 = canvas2.width;
        const contentHeight2 = canvas2.height;

        doc.addImage(canvas2.toDataURL('image/png'), 'PNG', 10, 10, 190, 0);

        const filename = `temp_rise_cal_report_${this.projectNo}_CUB_${this.cubicleId}.pdf`;

        doc.save(filename);
      });
    });
  }

  generateChart(): void {
    const chartData = this.calculateChartData();
    const canvas = document.getElementById('tempRiseChart') as HTMLCanvasElement;
    const ctx = canvas?.getContext('2d');

    if (ctx) {
      // Destroy the existing chart if it exists
      if (this.currentChart) {
        this.currentChart.destroy();
      }

      // Convert xValues to number[]
      const xValues = chartData.xValues.map(x => Number(x));
      const yValues = chartData.yValues;
      const n = xValues.length;

      const sumX = xValues.reduce((acc, x) => acc + x, 0);
      const sumY = yValues.reduce((acc, y) => acc + y, 0);
      const sumXY = xValues.reduce((acc, x, i) => acc + x * yValues[i], 0);
      const sumXX = xValues.reduce((acc, x) => acc + x * x, 0);

      const m = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
      const cRed = (sumY - m * sumX) / n;

      // Define the x-values range for the blue line
      const xMin = Math.min(...xValues);
      const xMax = Math.max(...xValues);

      // Calculate the y-intercept (cBlue) for the blue line
      let blueLineData: { x: number, y: number }[] = [];
      if (this.maxTemperatureAllowed) {
        const xBlue = this.maxTemperatureAllowed;
        const yBlue = 1.0;
        const cBlue = yBlue - m * xBlue;

        // Generate the full blue line data
        const xMinBlue = (1.0 - cBlue) / m;
        const xMaxBlue = (0 - cBlue) / m; // Assuming you want to show the range up to X where Y reaches 0, adjust if needed

        const xStart = Math.max(xMin, Math.min(xMaxBlue, xMinBlue));
        const xEnd = Math.min(xMax, Math.max(xMaxBlue, xMinBlue));
        const XRed = (this.reportData?.ambientTemperature ?? 0) + (this.reportData?.t100 ?? 0);
        const Gap = XRed - xBlue;
        // Adjust blue line data based on effective area condition
        if (this.reportData?.effectiveArea < 1.25) {
          // Draw the blue line points with the same slope as the red line

          const blueLineUnderCondition = [
            { x: xBlue, y: 1.0 },
            { x: xBlue, y: 0.75 },
            { x: xValues[2] - Gap, y: 0.5 },
            { x: xValues[1] - Gap, y: 0.25 },
            { x: xValues[0] - Gap, y: 0 },
          ];

          blueLineData = blueLineUnderCondition;
        } else {
          blueLineData = [
            { x: 0, y: cBlue }, // Adding point where x = 0
            { x: xBlue, y: 1.0 },
            { x: (0.75 - cBlue) / m, y: 0.75 }, // Add point at y = 0.75
            { x: xValues[2] - Gap, y: 0.5 }, // Add point at y = 0.5
            { x: xValues[1] - Gap, y: 0.25 },
            { x: xStart, y: m * xStart + cBlue },
            { x: xEnd, y: m * xEnd + cBlue }
          ];
        }
      }

      // Create a new chart
      this.currentChart = new Chart(ctx, {
        type: 'scatter',
        data: {
          datasets: [
            {
              label: 'Temperature rise (Without Fan)',
              data: chartData.xValues.map((x, index) => ({ x: Number(x), y: chartData.yValues[index] })),
              borderColor: 'red',
              backgroundColor: 'red',
              showLine: true, // Connect the points with a line
              borderWidth: 1,
              pointBorderWidth: 1,
              pointHoverRadius: 1,
              pointHoverBorderWidth: 1,
              pointRadius: 1,
              pointHitRadius: 1
            },
            // Conditionally add the blue line dataset
            ...(this.maxTemperatureAllowed ? [{
              label: 'Temperature rise (With Fan)',
              data: blueLineData,
              borderColor: 'blue',
              backgroundColor: 'blue',
              showLine: true, // Show the line
              borderWidth: 1,
              pointRadius: 1, // Adjust the size of the points
              pointBackgroundColor: 'blue',
              pointBorderColor: 'blue',
              pointHoverRadius: 7,
              pointHoverBackgroundColor: 'blue',
              pointHoverBorderColor: 'blue',
            }] : [])
          ]
        },
        options: {
          responsive: true,
          scales: {
            x: {
              type: 'linear',
              title: {
                display: true,
                text: 'Final Temperature Value',
                font: {
                  size: this.getFontSize(),
                  weight: '500'
                }
              },
              beginAtZero: true,
              min: 0, // Ensure x-axis starts at 0
              suggestedMin: 0, // Suggest starting at 0
            },
            y: {
              type: 'linear',
              title: {
                display: true,
                text: 'Multiple of enclosure height',
                font: {
                  size: this.getFontSize(),
                  weight: '500'
                }
              },
              beginAtZero: true,
              min: 0, // Ensure y-axis starts at 0
              max: 1,
              suggestedMax: 1.0 // Set max limit for the y-axis
            }
          },
          elements: {
            line: {
              tension: 0 // No curve in the lines
            },
          },
          plugins: {
            legend: {
              display: true
            }
          },
        }
      });
    }
  }

  // calculateChartData(): { labels: string[], data: number[] } {
  //   const labels: string[] = [];
  //   const data: number[] = [];
  //   const stepSize = 0.1;

  //   for (let x = this.curvedRangeStart; x <= this.rangeMax; x += stepSize) {
  //     labels.push(x.toFixed(2));

  //     if (x <= this.curvedRangeEnd) {
  //       // const yCurved = this.x * Math.exp(this.b * x + this.c);
  //       const yCurved = this.a * x * x;
  //       data.push(yCurved);
  //     } else {
  //       const yLinear = this.d * x + this.e;
  //       data.push(yLinear);
  //     }
  //   }
  //   return { labels, data };
  // }


  calculateChartData(): { xValues: string[], yValues: number[] } {
    let xValues: string[] = [];
    let yValues: number[] = [];

    // Assuming linearXValues and linearYValues are defined and populated
    if (this.linearXValues && this.linearYValues) {
      for (let i = 0; i < this.linearXValues.length; i++) {
        xValues.push(this.linearXValues[i].toFixed(2));
        yValues.push(this.linearYValues[i]);
      }
    }
    return { xValues, yValues };
  }

  getFontSize() {
    const screenWidth = window.innerWidth;
    return screenWidth < 576 ? 8 : 14;
  }
  onChangeTemp() {
    if (this.changeTemp === 'no') {
      this.maxTemperatureAllowed = 0;
      this.generateChart();
    } else {
      if (this.selectedElevation && this.maxTemperatureAllowed) {
        this.calculateRequiredCoolingPower();
        this.generateChart();
      }
    }
  }


  getFValue(height: number): number {
    if (height <= this.fTable[0].height) return this.fTable[0].f;
    if (height >= this.fTable[this.fTable.length - 1].height) return this.fTable[this.fTable.length - 1].f;

    for (let i = 0; i < this.fTable.length - 1; i++) {
      if (height >= this.fTable[i].height && height < this.fTable[i + 1].height) {
        const h1 = this.fTable[i].height;
        const h2 = this.fTable[i + 1].height;
        const f1 = this.fTable[i].f;
        const f2 = this.fTable[i + 1].f;
        return f1 + (f2 - f1) * ((height - h1) / (h2 - h1));
      }
    }
    return 0; // This line should never be reached
  }

  calculateRequiredCoolingPower() {
    if (this.reportData && this.reportData.ambientTemperature != null && this.reportData.t100 != null && this.reportData.height != null) {
      const deltaT = this.maxTemperatureAllowed - this.reportData.ambientTemperature;
      // const ConvertCFMUnit = 2.11888;
      const ConvertCFMUnit = 1;

      // Get the appropriate 'f' value based on the height
      const f = this.getFValue(this.selectedElevation);

      // Calculate the required airflow
      this.requiredCoolingPower = parseFloat(((this.reportData.powerloss * f * 1.2 * ConvertCFMUnit) / deltaT).toFixed(2));
      this.generateChart();
    }
  }

  calculateNumberOfFan() {
    if (this.requiredCoolingPower && this.fanCMFValue) {
      this.numberOfFan = Math.round(this.requiredCoolingPower / this.fanCMFValue);
    }
  }

}