<div class="btn-css">
    <button class="custom-upload-btn" (click)="downloadTemplate()">
        <span class="flex align-items-center">
            <i class="fa-solid fa-file-excel icon-margin"></i>
            DOWNLOAD TEMPLATE
        </span>
    </button>

    <label class="upload-btn">
        <input #fileInput type="file" accept=".xlsx, .xls" (change)="uploadCustomDeviceDetails($event)">
        <button class="custom-upload-btn" (click)="fileInput.click()">Upload Custom Device
            Details</button>
    </label>

</div>