<div class="terms-and-conditions-modal">
  <ul class="custom-list">
    <li>The KIKBLOX Temperature Software is exclusively designed for temperature rise verification of KIKBLOX brand
      enclosures and switchboard systems.The functionality of this Software is supported by verification testing
      conducted specifically on KIKBLOX products.Utilizing the Software for verification on enclosures and enclosure
      systems of brands other than KIKBLOX is not deemed as verifiable.Therefore, the Software cannot be used to
      demonstrate compliance to any standards when applied to non-KIKBLOX branded enclosures and enclosure systems.By
      using the KIKBLOX Temperature Software, you agree to these terms and conditions.
    </li>
    <li>For switchboard specific Design Verification compliance please discuss your requirements with KIK.Compliance
      will be subject to reconfirmation, in the effect of inability to provide specification at the time of estimation
      and in
      which case, we bear no responsibility to provide compliance.
    </li>
  </ul>
  <div class="disclaimer-notice">
    <h3 class="disclaimer-title">Disclaimer Notice</h3>
    <ul class="custom-list">
      <span class="disclaimer-text">By using our temperature rise calculation software, you acknowledge and agree to the
        following terms:</span>
      <li class="disclaimer-item">
        <span class="number">1.</span>
        <span class="disclaimer-subtitle">No Liability:</span> Our company, including its officers, directors,
        employees, and agents, shall not be held responsible or liable for any damages, losses, or issues arising from
        the use of this software. This includes, but is not limited to, any direct, indirect, incidental, consequential,
        or punitive damages.
      </li>
      <li class="disclaimer-item">
        <span class="number">2.</span>
        <span class="disclaimer-subtitle">Accuracy and Reliability:</span> While we strive to ensure the accuracy and
        reliability of the software, we make no guarantees or warranties of any kind, express or implied, regarding the
        software's performance, results, or accuracy. The calculations and recommendations provided are for
        informational purposes only.
      </li>
      <li class="disclaimer-item">
        <span class="number">3.</span>
        <span class="disclaimer-subtitle">User Responsibility:</span> Users are solely responsible for verifying the
        results and ensuring they meet the necessary standards and requirements for their specific applications. The
        software is a tool to assist in the calculation process and should not be the sole basis for decision-making.
      </li>
      <li class="disclaimer-item">
        <span class="number">4.</span>
        <span class="disclaimer-subtitle">Technical Issues:</span> We do not guarantee that the software will be free
        from errors, interruptions, or technical issues. Users should take appropriate measures to safeguard their data
        and systems.
      </li>
      <li class="disclaimer-item">
        <span class="number">5.</span>
        <span class="disclaimer-subtitle">Modifications and Updates:</span> We reserve the right to modify, update, or
        discontinue the software at any time without notice. Users are responsible for ensuring they are using the most
        current version of the software.
      </li>
      <span class="disclaimer-text">By signing up and using the software, you agree to hold our company harmless from
        any claims, liabilities, or expenses resulting from your use of the software. If you do not agree with these
        terms, please do not use the software.</span>
    </ul>
  </div>

  <div class="checkbox">
    <p-checkbox label="I have read and accept the Terms of Service" (onChange)="selectCheckbox($event)" [binary]="true"
      inputId="binary" [(ngModel)]="termsAccepted"></p-checkbox>
  </div>
</div>