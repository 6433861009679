<div class="forgot-password-page">
    <form [formGroup]="forgotPasswordForm" class="forgot-password-details">
        <div class="grid">
            <div class="col-10 sm:col-8 lg:col-5 xl:col-4">
                <h2>Forgot Password ?</h2>
                <p>No need to worry! We are here to assist you. Please enter your email address to get the password reset link.</p>
            </div>
        </div>

        <!-- Email-->
        <div class="form-field">
            <div class="grid">
                <div class="col-10 sm:col-8 md:col-5 lg:col-4 xl:col-3">
                    <div class="p-input-icon-left">
                        <i class="icon fas fa-envelope"></i>
                        <input pInputText #email class="input-field" placeholder="Enter registered email address"
                            formControlName="email" />
                    </div>
                    <div class="required"
                        *ngIf="forgotPasswordForm.controls['email'].hasError('required') && forgotPasswordForm.controls['email'].touched">
                        {{requiredField}}
                    </div>
                    <div class="required"
                        *ngIf="forgotPasswordForm.controls['email'].hasError('pattern') && forgotPasswordForm.controls['email'].touched">
                        {{enterValidInput}}
                    </div>
                </div>
            </div>
        </div>

        <div class="grid form-field">
            <div class="col-10 sm:col-8 md:col-5 lg:col-4 xl:col-3">
                <button (click)="forgotPassword()" [loading]="submittingData" pButton label="RESET PASSWORD"
                    [disabled]="forgotPasswordForm.invalid" class="forgot-password-button"></button>
            </div>
        </div>

        <div class="grid">
            <div class="col-10 md:col-5 lg:col-4 copyright">
                <i class="fa-regular fa-copyright"></i>2020 KIK LANKA (PVT) LTD
            </div>
        </div>
    </form>
</div>

<p-toast></p-toast>
<div class="kiktemp-loader" *ngIf="submittingData">
    <img src="../../../assets/img/kikblox_logo_rounded.webp" alt="kiktemp-loader" class="spinner" />
    <div class="loading-text">Loading...</div>
</div>