<div class="reset-password-page">
    <form [formGroup]="resetPasswordForm" class="reset-password-details">
        <div class="grid">
            <div class="col-10 sm:col-8 lg:col-5 xl:col-4">
                <h2>Reset Password</h2>
                <p>Welcome! You're almost there. Please provide a new password to complete the process.</p>
            </div>
        </div>

        <!-- New Password -->
        <div class="form-field">
            <div class="grid">
                <div class="col-10 sm:col-8 md:col-5 lg:col-4 xl:col-3">
                    <div class="p-input-icon-left">
                        <i class="icon fas fa-key"></i>
                        <div class="password-input">
                            <input onkeypress="return event.charCode != 32" [type]="newPasswordFieldType" pInputText
                                class="input-field" placeholder="Enter New Password" formControlName="newPassword" />
                            <button type="button" (click)="toggleNewPasswordVisibility()">
                                <i class="icon fas"
                                    [ngClass]="{'fa-eye': newPasswordFieldType === 'password', 'fa-eye-slash': newPasswordFieldType === 'text'}"></i>
                            </button>
                        </div>
                    </div>
                    <div class="required"
                        *ngIf="resetPasswordForm.controls['newPassword'].hasError('required') && resetPasswordForm.controls['newPassword'].touched">
                        {{requiredField}}
                    </div>
                    <div class="required"
                        *ngIf="resetPasswordForm.controls['newPassword'].hasError('minlength') && resetPasswordForm.controls['newPassword'].touched">
                        {{passwordLength}}
                    </div>
                </div>
            </div>
        </div>

        <!-- Confirm New Password -->
        <div class="form-field">
            <div class="grid">
                <div class="col-10 sm:col-8 md:col-5 lg:col-4 xl:col-3">
                    <div class="p-input-icon-left">
                        <i class="icon fas fa-key"></i>
                        <div class="password-input">
                            <input onkeypress="return event.charCode != 32" [type]="confirmPasswordFieldType" pInputText
                                class="input-field" placeholder="Confirm New Password"
                                formControlName="confirmedPassword" (onChange) = 'arePasswordsEqual()' />
                            <button type="button" (click)="toggleConfirmPasswordVisibility()">
                                <i class="icon fas"
                                    [ngClass]="{'fa-eye': confirmPasswordFieldType  === 'password', 'fa-eye-slash': confirmPasswordFieldType  === 'text'}"></i>
                            </button>
                        </div>
                    </div>
                    <div class="required"
                        *ngIf="resetPasswordForm.controls['confirmedPassword'].hasError('required') && resetPasswordForm.controls['confirmedPassword'].touched">
                        {{requiredField}}
                    </div>
                    <div class="required"
                        *ngIf="!resetPasswordForm.controls['confirmedPassword'].hasError('required') && resetPasswordForm.controls['confirmedPassword'].touched && !arePasswordsEqual()">
                        {{passwordMissmatch}}
                    </div>
                </div>
            </div>
        </div>

        <div class="grid form-field">
            <div class="col-10 sm:col-8 md:col-5 lg:col-4 xl:col-3">
                <button (click)="resetPassword()" [loading]="submittingData" pButton label="SUBMIT"
                    [disabled]="resetPasswordForm.invalid || !arePasswordsEqual()"
                    class="reset-password-button"></button>
            </div>
        </div>

        <div class="grid">
            <div class="col-10 md:col-5 lg:col-4 copyright">
                <i class="fa-regular fa-copyright"></i>2020 KIK LANKA (PVT) LTD
            </div>
        </div>
    </form>
</div>

<p-toast></p-toast>
<div class="kiktemp-loader" *ngIf="submittingData">
    <img src="../../../assets/img/kikblox_logo_rounded.webp" alt="kiktemp-loader" class="spinner" />
    <div class="loading-text">Loading...</div>
</div>