<div class="content-body project-overview-page">

    <div class="page-header">Project Overview</div>

    <div style="margin: 10px 50px;"><button [routerLink]="['/past-projects']" pbutton icon="fas fa-backward" pButton
            label="Back to Past Projects" class="cancel-btn"></button></div>
    <div class="project-overview">
        <div class="grid update-project">
            <button *ngIf="userId == projectCreatedUserId" icon="fas fa-pen" pButton title="Update Project" class=""
                [loading]="addingCubicle" (click)="changeMainData()"></button>
        </div>
        <div class="grid">
            <div class="field col-12 sm:col-4">
                <label htmlFor="projectNo">Project No</label>
                <div id="projectNo" class="data-field">{{projectNo}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label htmlFor="projectName">Project Name</label>
                <div id="projectName" class="data-field">{{projectDataVM.projectName}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label htmlFor="typeOfInstallation">Type of Installation</label>
                <div id="typeOfInstallation" class="data-field">{{projectDataVM.typeOfInstallation}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label htmlFor="demandFactor">Load Factor</label>
                <div id="demandFactor" class="data-field">{{projectDataVM.demandFactor}}</div>
            </div>

            <!-- <div class="field col-12 sm:col-4">
                <label htmlFor="elevation">Elevation (m)</label>
                <div id="elevation" class="data-field">{{projectDataVM.elevation}}</div>
            </div> -->

            <div class="field col-12 sm:col-4">
                <label htmlFor="ambientTemperature">Ambient Temperature (&#8451;)</label>
                <div id="ambientTemperature" class="data-field">{{projectDataVM.ambientTemperature}}</div>
            </div>
        </div>
    </div>

    <div class="add-cubicle-btn">
        <button *ngIf="userId == projectCreatedUserId" pbutton icon="fas fa-circle-plus" pButton
            [label]="addingCubicle ? 'ADDING CUBICLE...' : 'ADD CUBICLE'" class="" [loading]="addingCubicle"
            (click)="addCubicle()"></button>
    </div>

    <!-- <div class="cubicle-list" *ngIf="!isGettingData">
        <p-table [value]="cubicleList" styleClass="p-datatable-striped" [tableStyle]="{'min-width': '50rem'}" [paginator]="true" [rows]="5">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 20%;" pSortableColumn="date">Date <p-sortIcon [field]="'date'"></p-sortIcon></th>
                    <th style="width: 40%;">Cubicle ID</th>
                    <th style="width: 40%;">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cubicle>
                <tr>
                    <td>{{cubicle.createdAt}}</td>
                    <td>{{cubicle.id}}</td>
                    <td>
                        <div class="button-container">
                            <button pbutton icon="fas fa-eye" (click)="navigateToViewReport(cubicle)" pButton label="{{screenWidth < mobileWidth ? '': 'View Report'}}" class="view-btn" [disabled]="cubicle.POWER_LOSS === 0"></button>
                            <button *ngIf="userId == projectCreatedUserId" pbutton icon="fas fa-pen" pButton label="{{screenWidth < mobileWidth ? '': 'Update'}}" class="update-btn" (click)="updateCubicle(cubicle)"></button>
                            <button *ngIf="userId == projectCreatedUserId" pbutton icon="fas fa-trash" pButton label="{{screenWidth < mobileWidth ? '': 'Delete'}}" class="delete-btn" (click)="showDeleteConfirm(cubicle)"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div> -->

    <div class="cubicle-list" *ngIf="!isGettingData">
        <p-table *ngIf="cubicleList" [value]="cubicleList" styleClass="p-datatable-striped"
            [tableStyle]="{'min-width': '50rem'}" [paginator]="true" [rows]="5">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 20%;" pSortableColumn="date">Date <p-sortIcon [field]="'date'"></p-sortIcon></th>
                    <th style="width: 40%;">Cubicle ID</th>
                    <th style="width: 40%;">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cubicle>
                <tr>
                    <td>{{cubicle.createdAt}}</td>
                    <td>{{cubicle.id}}</td>
                    <td>
                        <div class="button-container">
                            <button pbutton icon="fas fa-eye" (click)="navigateToViewReport(cubicle)" pButton
                                label="{{screenWidth < mobileWidth ? '': 'View Report'}}" class="view-btn"
                                [disabled]="cubicle.POWER_LOSS === 0"></button>
                            <button *ngIf="userId == projectCreatedUserId" pbutton icon="fas fa-pen" pButton
                                label="{{screenWidth < mobileWidth ? '': 'Update'}}" class="update-btn"
                                (click)="updateCubicle(cubicle)"></button>
                            <button *ngIf="userId == projectCreatedUserId" pbutton icon="fas fa-trash" pButton
                                label="{{screenWidth < mobileWidth ? '': 'Delete'}}" class="delete-btn"
                                (click)="showDeleteConfirm(cubicle)"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="no-projects" *ngIf="cubicleList && cubicleList.length === 0">
            <h3>No Cubicle data found</h3>
            <img src="../../../assets/img/no_data.webp" class="no-data-img">
        </div>
    </div>

    <!-- <div>
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="tableRowsPerPage"
            [totalRecords]="totalRecords"></p-paginator>
    </div> -->

    <div class="col-12 md:col-6 lg:col-5 pl-5 pr-4 " *ngIf="isGettingData || savingData">
        <div class="kiktemp-loader">
            <img src="assets/img/kikblox_logo_rounded.webp" alt="Logo" class="spinner" />
            <div class="loading-text">Loading...</div>
        </div>
    </div>
</div>

<p-dialog [modal]="true" [(visible)]="isEditingMainData" class="edit-project-main-data"
    (onHide)="loadProjectData(projectId)">
    <ng-template pTemplate="header">
        <h5 style="width: 100%; text-align: center; color: #185ADB;">Project Overview</h5>
    </ng-template>
    <div class="grid">
        <form [formGroup]="newProjectForm">
            <div class="col-12 edit-project-main-data-content text-center">
                <div class="col-12">
                    <!-- projectNo -->
                    <div class="grid form-field mt-4">
                        <label htmlFor="projectNo" class="ml-1 mb-2">Project No <span class="required">*</span></label>
                        <input pInputText id="projectNo" value="{{projectNo}}" readonly />
                    </div>

                    <!-- projectName -->
                    <div class="grid form-field mt-4">
                        <label htmlFor="projectName" class="ml-1 mb-2">Project Name <span
                                class="required">*</span></label>
                        <input pInputText id="projectName" formControlName='projectName' />
                    </div>

                    <!-- typeOfInstallation -->
                    <div class="grid form-field mt-4 flex flex-column text-left">
                        <label htmlFor="typeOfInstallation" class="ml-1 mb-2">Installation Type <span
                                class="required">*</span></label>
                        <p-dropdown optionLabel="value" id="typeOfInstallation" styleClass="custom-drop-down"
                            [options]="installationTypes" formControlName="typeOfInstallation"
                            placeholder="Select Installation Type">
                        </p-dropdown>
                    </div>

                    <!-- demandFactor -->
                    <div class="grid form-field mt-4">
                        <label htmlFor="demandFactor" class="ml-1 mb-2">Load Factor <span
                                class="required">*</span></label>
                        <input pInputText id="demandFactor" formControlName='demandFactor' />
                    </div>

                    <!-- elevation -->
                    <!-- <div class="grid form-field mt-4 flex flex-column text-left">
                        <label htmlFor="elevation" class="ml-1 mb-2">Elevation <span class="required">*</span></label>
                        <p-dropdown optionLabel="elevation" id="elevation" styleClass="custom-drop-down"
                            [options]="elevationArray" formControlName="elevation" placeholder="Select Elevation">
                        </p-dropdown>
                    </div> -->

                    <!-- ambientTemperature -->
                    <div class="grid form-field mt-4">
                        <label htmlFor="ambientTemperature" class="ml-1 mb-2">Ambient Temperature <span
                                class="required">*</span></label>
                        <input pInputText id="ambientTemperature" formControlName='ambientTemperature' />
                    </div>
                </div>

                <div class="col-12 flex flex-row justify-content-end mt-5 mb-3">
                    <button label="UPDATE" class="save-btn" pButton [disabled]="!hasMainDataChanges"
                        (click)="updateData()"></button>
                </div>
            </div>
        </form>
    </div>
</p-dialog>



<!-- Delete confirmation modal  -->
<p-dialog [modal]="true" [(visible)]="isShowDeleteConfirmationPopup" class="edit-project-main-data">
    <ng-template pTemplate="header">
        <h5 style="width: 100%; text-align: center; color: #bc351f;">Delete a Cubicle</h5>
    </ng-template>
    <div class="grid">
        <div class="col-12 cubicle-overview-delete-confirmation-modal-content">
            <p>Are you sure you want to delete this record ?</p>
            <p>This action can't be undone.</p>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 text-right mb-5 pr-5">
            <button pButton class="cancel-btn" (click)="cancelDeleteData()">CANCEL</button>
            <button pButton class="delete-btn" (click)="deleteCubicle()">DELETE</button>
        </div>
    </div>
</p-dialog>

<div class="col-12 md:col-6 lg:col-5 pl-5 pr-4 " *ngIf="isGettingData">
    <div class="kiktemp-loader">
        <img src="assets/img/kikblox_logo_rounded.webp" alt="New Project Image" class="spinner" />
        <div class="loading-text">Loading...</div>
    </div>
</div>