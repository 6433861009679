<div class="content-body user-overview-page">
    <h4 class="page-header">User Overview</h4>

    <div class="user-details">
        <div class="grid" *ngIf="showParent === 1">
            <div class="field col-12 sm:col-4">
                <label>Distributor's Organization Name</label>
                <div class="data-field distributor">{{parentOrganization}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label>Distributor's Contact Person Name</label>
                <div class="data-field distributor">{{parentName}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label>Distributor's Email</label>
                <div class="data-field distributor">{{parentEmail}}</div>
            </div>
        </div>
        <div class="grid">
            <div class="field col-12 sm:col-4">
                <label>Organization Name</label>
                <div class="data-field">{{childOrganizationName}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label>Contact Person Name</label>
                <div class="data-field">{{childName}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label>Email</label>
                <div class="data-field">{{childEmail}}</div>
            </div>
        </div>
        <div class="grid">
            <div class="field col-12 sm:col-4">
                <label>Contact Number</label>
                <div class="data-field">{{childPhoneNumber}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label>Country</label>
                <div class="data-field">{{childCountry}}</div>
            </div>

            <div class="field col-12 sm:col-4">
                <label>Joined Date</label>
                <div class="data-field">{{joinedDate}}</div>
            </div>
        </div>
        <div class="grid" *ngIf="loggedInUserType === 'admin'">
            <div class="field col-12 sm:col-4">
                <label>User Type</label>
                <div class="data-field">{{userType | titlecase }}</div>
            </div>
        </div>
    </div>

    <div class="search-container">
        <div class="p-inputgroup col-12 sm:col-5">
            <span class="p-inputgroup-addon">
                <i class="fas fa-search"></i>
            </span>
            <input title="Search by Project No / Project Name" pInputText
                placeholder="Search by Project No / Project Name" (keyup)="searchProject($event)">
        </div>
    </div>

    <div class="card">
        <div *ngIf="projectDetails.length !== 0">
            <p-table [value]="projectDetails" styleClass="p-datatable-striped" [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="CREATED_DATE" style="width: 10%;">Date <p-sortIcon
                                [field]="'CREATED_DATE'"></p-sortIcon>
                        </th>
                        <th style="width: 15%;">Project No</th>
                        <th style="width: 15%;">Project Name</th>
                        <th style="width: 15%;">Installation Type</th>
                        <th style="width: 10%;">Load Factor</th>
                        <!-- <th style="width: 10%;">Elevation(m)</th> -->
                        <th style="width: 15%;">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-project>
                    <tr>
                        <td>{{project.CREATED_DATE}}</td>
                        <td>{{project.PROJECT_ID}}</td>
                        <td>{{project.PROJECT_NAME}}</td>
                        <td>{{project.INSTALLATION_TYPE}}</td>
                        <td>{{project.DEMAND_FACTOR}}</td>
                        <!-- <td>{{project.ELEVATION}}</td> -->
                        <td>
                            <button class="view-project-btn" (click)="viewProject(project)">View Project
                                <i class="fa-solid fa-square-arrow-up-right navigate"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="no-projects" *ngIf="projectDetails.length === 0">
            <h3>No Projects found!</h3>
            <img src="../../../assets/img/no_data.webp" class="no-data-img">
        </div>
    </div>

    <div *ngIf="projectDetails.length !== 0">
        <p-paginator (onPageChange)="onPaginate($event)" [first]="first" [pageLinkSize]="3" [rows]="tableRowsPerPage"
            [totalRecords]="totalRecords"></p-paginator>
    </div>
</div>

<p-toast></p-toast>
<div class="kiktemp-loader" *ngIf="isGettingData">
    <img src="../../../assets/img/kikblox_logo_rounded.webp" alt="kiktemp-loader" class="spinner" />
    <div class="loading-text">Loading...</div>
</div>